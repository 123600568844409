import styled from "styled-components";
import { media } from "./../../common/MediaQueries";

const PageNotFoundStyled = styled.div`
  width: 100%;
  max-width: 560px;
  margin: 0 auto;
`;

const PageNotFoundHeadStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 88px;
  font-family: ${({ theme }) => theme.fontFamilies.proximaNova};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  line-height: 1.2;

  span {
    position: relative;
  }

  @media ${media.phone} {
    font-size: 206px;
    letter-spacing: -10px;
  }
`;

const PageNotFoundHeadMediaStyled = styled.span`
  position: relative;
`;
const PageNotFoundHeadMediaItemStyled = styled.div`
  display: none;
  position: absolute;
  width: 116px;
  top: 39%;
  left: 54%;
  transform: translate(-50%, -50%);
  z-index: 2;

  @media ${media.phone} {
    display: block;
  }
`;

const PageNotFoundHeadMediaTextStyled = styled.span`
  display: inline;
`;

const PageNotFoundDescStyled = styled.div`
  text-transform: uppercase;
  span {
    color: ${({ theme }) => theme.colors.watermelon};
  }
`;

const PageNotFoundBodyStyled = styled.div`
  margin-bottom: 50px;
  @media ${media.phone} {
    margin-bottom: 104px;
  }
`;

const PageNotFoundFooterStyled = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

export {
  PageNotFoundStyled,
  PageNotFoundHeadStyled,
  PageNotFoundDescStyled,
  PageNotFoundBodyStyled,
  PageNotFoundFooterStyled,
  PageNotFoundHeadMediaStyled,
  PageNotFoundHeadMediaItemStyled,
  PageNotFoundHeadMediaTextStyled,
};
